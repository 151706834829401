.platform {
  margin: auto !important;
  width: 90vw !important;
  height: 80vh !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; }
  .platform #\#canvas {
    width: 100%;
    height: 100%; }
