.chat .row {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px; }

.chat .conversations-list {
  border-radius: 10px 0 0 10px;
  background-color: #fafcff;
  border-right: 1px solid rgba(0, 98, 211, 0.5);
  padding: 0px;
  overflow: auto; }
  .chat .conversations-list > div {
    cursor: pointer;
    height: 80px;
    border-bottom: 1px solid rgba(0, 98, 211, 0.15);
    display: flex; }
    .chat .conversations-list > div[selected] {
      background-color: #f3f3f3; }
    .chat .conversations-list > div:hover {
      box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15); }
    .chat .conversations-list > div .img-container {
      padding: 0 10px;
      display: inline-block; }
      .chat .conversations-list > div .img-container img {
        margin-top: calc((80px - 55px) / 2);
        height: 55px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15); }
    .chat .conversations-list > div .text-container {
      width: 100%;
      padding: 10px 13px;
      overflow: hidden; }
      .chat .conversations-list > div .text-container > span:first-of-type {
        overflow: hidden;
        font-weight: 500;
        font-size: 23px;
        display: inline; }
      .chat .conversations-list > div .text-container .text-preview {
        overflow: hidden; }
        .chat .conversations-list > div .text-container .text-preview span {
          display: block;
          font-size: 15px;
          white-space: pre; }

.chat .conversation {
  padding: 0;
  display: flex; }
  .chat .conversation > div {
    width: 100%; }
  .chat .conversation .first-conversation {
    width: 90%;
    margin: auto;
    text-align: center;
    font-family: "Roboto";
    font-size: 40px;
    font-weight: 100; }
    .chat .conversation .first-conversation span {
      animation: 0.5s ease-out 0s 1 zoom-in; }
  .chat .conversation .input-container {
    text-align: left;
    font-size: 15px;
    bottom: 0;
    left: 0;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    display: inline-block;
    z-index: 1; }
    .chat .conversation .input-container .fav-emojis {
      position: fixed;
      height: 40px;
      text-align: center;
      border-radius: 5px;
      padding: 5px 3px;
      background-color: rgba(0, 0, 0, 0.05); }
      .chat .conversation .input-container .fav-emojis > .emoji-mart-emoji {
        margin: 0 5px;
        cursor: pointer;
        border-radius: 50%;
        background-color: rgba(216, 216, 216, 0.5); }
        .chat .conversation .input-container .fav-emojis > .emoji-mart-emoji span {
          transition: 0.2s ease-out; }
          .chat .conversation .input-container .fav-emojis > .emoji-mart-emoji span:hover {
            transform: scale(1.6) translateY(-20%); }
    .chat .conversation .input-container .input-buttons-container {
      border-top: 1px solid rgba(0, 98, 211, 0.3);
      height: 100%;
      display: flex; }
      .chat .conversation .input-container .input-buttons-container textarea {
        border: none;
        border-right: 1px solid rgba(0, 98, 211, 0.3);
        resize: none;
        width: 90%;
        height: 100%; }
      .chat .conversation .input-container .input-buttons-container .form-buttons {
        min-width: 150px;
        display: flex; }
        .chat .conversation .input-container .input-buttons-container .form-buttons button.send {
          height: 50%;
          min-height: 35px;
          margin: auto; }
        .chat .conversation .input-container .input-buttons-container .form-buttons .emojis {
          margin: auto; }
          .chat .conversation .input-container .input-buttons-container .form-buttons .emojis .overlay {
            position: fixed;
            visibility: hidden; }
            .chat .conversation .input-container .input-buttons-container .form-buttons .emojis .overlay .emoji-mart-emoji > span {
              cursor: pointer; }
          .chat .conversation .input-container .input-buttons-container .form-buttons .emojis .emoji-mart-preview {
            height: 50px; }
          .chat .conversation .input-container .input-buttons-container .form-buttons .emojis div.button {
            cursor: pointer; }
  .chat .conversation .messages {
    width: 100%;
    padding: 10px 0;
    height: calc(100% - 80px);
    overflow: auto; }
    .chat .conversation .messages .message {
      width: 98%;
      margin: 5px auto; }
      .chat .conversation .messages .message p {
        font-size: 17px;
        margin: 0;
        white-space: pre-wrap;
        display: inline-block;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: whitesmoke;
        text-align: left;
        max-width: 50%;
        word-break: break-all; }
      .chat .conversation .messages .message.me {
        text-align: right; }
        .chat .conversation .messages .message.me p {
          background-color: #4387e4;
          color: white; }
    .chat .conversation .messages .time {
      position: fixed;
      visibility: hidden;
      background-color: #d0e9ff;
      padding: 4px;
      border-radius: 5px; }

@keyframes zoom-in {
  from {
    transform: scale(0.2); }
  to {
    transform: scale(1); } }
