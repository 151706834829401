.tictactoe {
  margin-top: 2rem; }
  .tictactoe form {
    display: inline-block; }

.Toastify__toast-body {
  text-align: center; }

.Toastify__toast {
  border-radius: 10px !important;
  min-height: 55px !important;
  max-width: 60vw;
  margin: auto; }

ol,
ul {
  padding-left: 30px; }

.board-row:after {
  clear: both;
  content: "";
  display: table; }

.status {
  margin-bottom: 10px; }

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px; }

.square:focus {
  outline: none; }

.kbd-navigation .square:focus {
  background: #ddd; }

.game {
  display: inline-flex; }

.game-info {
  margin-left: 20px; }
