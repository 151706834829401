.portfolio #Media {
  padding: 0; }
  .portfolio #Media img {
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    position: relative;
    overflow: hidden;
    object-fit: cover; }
  .portfolio #Media .legend {
    position: absolute;
    bottom: 3px;
    right: 10px;
    font-weight: 100;
    color: rgba(245, 245, 245, 0.74); }
  .portfolio #Media .write-auto {
    position: absolute;
    top: 30vmin;
    left: 8%;
    max-width: 40vw;
    color: white;
    font-weight: 1000;
    font-size: 3.5em; }
    .portfolio #Media .write-auto::after {
      color: black; }
