@import url("https://fonts.googleapis.com/css?family=Roboto:100,400");
.fa-times.delete-search {
  color: #d65a5a;
  font-size: 1.5rem; }

.friends {
  display: flow-root;
  border-radius: 3px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.09);
  padding: 0.5rem;
  margin-top: 1rem;
  text-align: center;
  border: 1px solid #a7b6cc; }
  .friends:hover {
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2); }
  .friends .fa-check {
    font-size: 1.4rem; }
  .friends h5 {
    margin-bottom: 0;
    word-break: break-all; }
  .friends h1 {
    font-family: 'Roboto';
    font-weight: 100; }
  .friends .user-card {
    border-radius: 15px;
    box-sizing: border-box;
    display: inline-flex;
    margin: 10px;
    width: 150px;
    min-height: 190px;
    padding: 5px 10px 5px 10px;
    display: inline-table;
    box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1); }
    .friends .user-card:hover {
      box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.17); }
    .friends .user-card.PENDING {
      border: 1px solid #19a4dc; }
  .friends .share {
    padding: 2px 4px 1px 4px;
    margin: 6px; }
    .friends .share i {
      margin-left: 7px; }

.user-img {
  min-width: 80px;
  height: 80px;
  margin: 5px auto;
  border-radius: 50%; }

.user-name {
  display: block; }

.user-status {
  display: table-row;
  margin-left: auto; }

.user-status > button {
  margin-bottom: 5px; }

.removeFriend {
  cursor: pointer;
  color: #ff4a4a !important;
  padding-bottom: 2px;
  border-bottom: 1px solid #a54848; }

.removeFriend:hover {
  border-bottom: 1px dotted red; }

.REQUEST {
  animation: infinite colorchanger 1.5s; }

@keyframes colorchanger {
  0% {
    border: 1px solid #19dc91; }
  50% {
    border: 1px solid white; }
  100% {
    border: 1px solid #19dc91; } }

.PENDING p {
  animation: anim 1.5s infinite alternate ease-in-out;
  color: #dcf5ff;
  font-size: 1.2rem;
  margin: 0.45rem; }

@keyframes anim {
  from {
    color: transparent; }
  to {
    color: #124775; } }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  cursor: pointer; }
  .popup > div:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #e9f5ff;
    color: #1e486f;
    max-width: 60vw;
    text-align: center;
    cursor: initial;
    padding: 1rem;
    border-radius: 5px;
    font-size: 2rem; }
    .popup > div:first-child h2 {
      display: inline;
      font-weight: 900; }
    .popup > div:first-child select {
      outline: none;
      background-color: #e9f5ff;
      border: none;
      cursor: pointer;
      color: #1e486f;
      margin: 0 1rem;
      border: none;
      border-bottom: 2px solid #1e486f; }
    .popup > div:first-child .list-group {
      display: block; }
    .popup > div:first-child .list-group-item {
      display: inline-block;
      font-size: 1.1rem;
      margin: 5px;
      width: auto; }
      .popup > div:first-child .list-group-item i {
        cursor: pointer;
        margin-left: 8px; }

.user-data h1 {
  display: inline-block; }

.search {
  width: 40%;
  min-width: 200px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url("https://www.w3schools.com/howto/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  margin: 10px;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  outline: none; }

.search:focus {
  width: 60%; }
