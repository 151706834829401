@import url(https://fonts.googleapis.com/css?family=Roboto:100,400);
@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Lato:400,900i);
.col {
  text-align: center; }

.card-title {
  font-size: 2rem; }

.card-text {
  text-align: justify; }

.sort-container {
  left: 70px;
  z-index: 1;
  color: white;
  background: #2658a9;
  position: absolute;
  margin-top: 10px;
  padding-top: 5px;
  width: 200px;
  height: 80px;
  border-radius: 5px;
  box-shadow: 0px 1.5px 8px 0.5px rgba(0, 0, 0, 0.2); }
  .sort-container:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #2658a9;
    border-left: 0;
    margin-top: -40px;
    margin-left: -10px; }
  .sort-container button {
    cursor: pointer; }

button.filter {
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: 50%;
  position: absolute;
  padding: 10px 15px;
  margin: 10px; }
  button.filter .fa-filter:before {
    font-size: 1.5rem; }

.apps-wrapper {
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding: 0 4vw; }
  .apps-wrapper > div {
    margin-top: 10px; }
  .apps-wrapper .card {
    left: 0px;
    top: 0px;
    border: unset;
    box-shadow: 0px 1px 3px 0.5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    box-sizing: border-box;
    display: inline-flex;
    margin: 10px;
    padding: 0 0 3px 0;
    width: 250px;
    height: 200px;
    z-index: 0;
    overflow: overlay;
    transition: 0.25s ease; }
    .apps-wrapper .card:hover {
      transform: translateY(-10px);
      box-shadow: 0px 1.5px 8px 0.5px rgba(0, 0, 0, 0.25); }
    .apps-wrapper .card[private="true"] {
      -webkit-filter: opacity(50%);
              filter: opacity(50%); }
    .apps-wrapper .card[disabled] {
      -webkit-filter: opacity(50%);
              filter: opacity(50%);
      pointer-events: none; }
    .apps-wrapper .card .fa {
      margin-left: 3px; }
    .apps-wrapper .card .btn {
      margin-bottom: 2px; }
    .apps-wrapper .card .card-title {
      margin-bottom: 0.55rem;
      padding-bottom: 0.35rem;
      border-bottom: 1px solid #4285f4; }
    .apps-wrapper .card .card-body {
      padding-top: 10px; }
    .apps-wrapper .card .card-header {
      border-radius: 15px 15px 0 0;
      background-color: rgba(44, 104, 218, 0.5);
      cursor: -webkit-grab;
      cursor: grab; }
    .apps-wrapper .card .card-bottom > span {
      font-style: italic;
      font-size: 0.78rem;
      display: block;
      margin-top: 2px;
      color: #747373; }

.todolist button.cancel {
  margin-left: 10px; }

.todolist .list-group-item {
  padding: 0.25rem 0.65rem;
  display: table;
  justify-content: space-between;
  width: 100%; }
  .todolist .list-group-item .text {
    display: table-cell;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    vertical-align: middle; }
  .todolist .list-group-item .buttons {
    height: 48.25px;
    width: 184px;
    display: table-cell;
    vertical-align: middle; }
    .todolist .list-group-item .buttons button {
      width: 48px;
      padding: 0.34rem 0.94rem; }
  .todolist .list-group-item:not(.list-group-item-action) .text {
    color: gray; }
  .todolist .list-group-item:not(.list-group-item-action) .buttons {
    opacity: 0.4; }

.todolist .list-group {
  display: inline-block;
  text-align: center; }
  .todolist .list-group > div {
    text-align: left;
    width: 49%;
    margin: 2px 0.5%;
    display: inline-flex; }

.fa-times.delete-search {
  color: #d65a5a;
  font-size: 1.5rem; }

.friends {
  display: flow-root;
  border-radius: 3px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.09);
  padding: 0.5rem;
  margin-top: 1rem;
  text-align: center;
  border: 1px solid #a7b6cc; }
  .friends:hover {
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2); }
  .friends .fa-check {
    font-size: 1.4rem; }
  .friends h5 {
    margin-bottom: 0;
    word-break: break-all; }
  .friends h1 {
    font-family: 'Roboto';
    font-weight: 100; }
  .friends .user-card {
    border-radius: 15px;
    box-sizing: border-box;
    display: inline-flex;
    margin: 10px;
    width: 150px;
    min-height: 190px;
    padding: 5px 10px 5px 10px;
    display: inline-table;
    box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1); }
    .friends .user-card:hover {
      box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.17); }
    .friends .user-card.PENDING {
      border: 1px solid #19a4dc; }
  .friends .share {
    padding: 2px 4px 1px 4px;
    margin: 6px; }
    .friends .share i {
      margin-left: 7px; }

.user-img {
  min-width: 80px;
  height: 80px;
  margin: 5px auto;
  border-radius: 50%; }

.user-name {
  display: block; }

.user-status {
  display: table-row;
  margin-left: auto; }

.user-status > button {
  margin-bottom: 5px; }

.removeFriend {
  cursor: pointer;
  color: #ff4a4a !important;
  padding-bottom: 2px;
  border-bottom: 1px solid #a54848; }

.removeFriend:hover {
  border-bottom: 1px dotted red; }

.REQUEST {
  -webkit-animation: infinite colorchanger 1.5s;
          animation: infinite colorchanger 1.5s; }

@-webkit-keyframes colorchanger {
  0% {
    border: 1px solid #19dc91; }
  50% {
    border: 1px solid white; }
  100% {
    border: 1px solid #19dc91; } }

@keyframes colorchanger {
  0% {
    border: 1px solid #19dc91; }
  50% {
    border: 1px solid white; }
  100% {
    border: 1px solid #19dc91; } }

.PENDING p {
  -webkit-animation: anim 1.5s infinite alternate ease-in-out;
          animation: anim 1.5s infinite alternate ease-in-out;
  color: #dcf5ff;
  font-size: 1.2rem;
  margin: 0.45rem; }

@-webkit-keyframes anim {
  from {
    color: transparent; }
  to {
    color: #124775; } }

@keyframes anim {
  from {
    color: transparent; }
  to {
    color: #124775; } }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  cursor: pointer; }
  .popup > div:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #e9f5ff;
    color: #1e486f;
    max-width: 60vw;
    text-align: center;
    cursor: auto;
    cursor: initial;
    padding: 1rem;
    border-radius: 5px;
    font-size: 2rem; }
    .popup > div:first-child h2 {
      display: inline;
      font-weight: 900; }
    .popup > div:first-child select {
      outline: none;
      background-color: #e9f5ff;
      border: none;
      cursor: pointer;
      color: #1e486f;
      margin: 0 1rem;
      border: none;
      border-bottom: 2px solid #1e486f; }
    .popup > div:first-child .list-group {
      display: block; }
    .popup > div:first-child .list-group-item {
      display: inline-block;
      font-size: 1.1rem;
      margin: 5px;
      width: auto; }
      .popup > div:first-child .list-group-item i {
        cursor: pointer;
        margin-left: 8px; }

.user-data h1 {
  display: inline-block; }

.search {
  width: 40%;
  min-width: 200px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url("https://www.w3schools.com/howto/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  margin: 10px;
  transition: width 0.3s ease-in-out;
  outline: none; }

.search:focus {
  width: 60%; }

.changeImage input {
  display: inline-block;
  width: 50% !important; }

.changeImage h2 {
  font-weight: 100; }

.tictactoe {
  margin-top: 2rem; }
  .tictactoe form {
    display: inline-block; }

.Toastify__toast-body {
  text-align: center; }

.Toastify__toast {
  border-radius: 10px !important;
  min-height: 55px !important;
  max-width: 60vw;
  margin: auto; }

ol,
ul {
  padding-left: 30px; }

.board-row:after {
  clear: both;
  content: "";
  display: table; }

.status {
  margin-bottom: 10px; }

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px; }

.square:focus {
  outline: none; }

.kbd-navigation .square:focus {
  background: #ddd; }

.game {
  display: inline-flex; }

.game-info {
  margin-left: 20px; }

.live {
  margin-top: 3vh; }
  .live h1 {
    display: inline; }
  .live h2 {
    font-family: 'Roboto';
    font-weight: 100;
    display: inline-block; }
  .live input {
    min-width: 100px;
    width: 20vw;
    display: inline-block; }
  .live span {
    display: inline-block;
    font-size: 2rem;
    background-color: #b3e0e7;
    border-radius: 5px;
    padding: 0.6rem; }

@media screen and (max-width: 550px) {
  .live {
    margin-top: 30vh; } }

.live-game {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; }

.chat .row {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px; }

.chat .conversations-list {
  border-radius: 10px 0 0 10px;
  background-color: #fafcff;
  border-right: 1px solid rgba(0, 98, 211, 0.5);
  padding: 0px;
  overflow: auto; }
  .chat .conversations-list > div {
    cursor: pointer;
    height: 80px;
    border-bottom: 1px solid rgba(0, 98, 211, 0.15);
    display: flex; }
    .chat .conversations-list > div[selected] {
      background-color: #f3f3f3; }
    .chat .conversations-list > div:hover {
      box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15); }
    .chat .conversations-list > div .img-container {
      padding: 0 10px;
      display: inline-block; }
      .chat .conversations-list > div .img-container img {
        margin-top: calc((80px - 55px) / 2);
        height: 55px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15); }
    .chat .conversations-list > div .text-container {
      width: 100%;
      padding: 10px 13px;
      overflow: hidden; }
      .chat .conversations-list > div .text-container > span:first-of-type {
        overflow: hidden;
        font-weight: 500;
        font-size: 23px;
        display: inline; }
      .chat .conversations-list > div .text-container .text-preview {
        overflow: hidden; }
        .chat .conversations-list > div .text-container .text-preview span {
          display: block;
          font-size: 15px;
          white-space: pre; }

.chat .conversation {
  padding: 0;
  display: flex; }
  .chat .conversation > div {
    width: 100%; }
  .chat .conversation .first-conversation {
    width: 90%;
    margin: auto;
    text-align: center;
    font-family: "Roboto";
    font-size: 40px;
    font-weight: 100; }
    .chat .conversation .first-conversation span {
      -webkit-animation: 0.5s ease-out 0s 1 zoom-in;
              animation: 0.5s ease-out 0s 1 zoom-in; }
  .chat .conversation .input-container {
    text-align: left;
    font-size: 15px;
    bottom: 0;
    left: 0;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    display: inline-block;
    z-index: 1; }
    .chat .conversation .input-container .fav-emojis {
      position: fixed;
      height: 40px;
      text-align: center;
      border-radius: 5px;
      padding: 5px 3px;
      background-color: rgba(0, 0, 0, 0.05); }
      .chat .conversation .input-container .fav-emojis > .emoji-mart-emoji {
        margin: 0 5px;
        cursor: pointer;
        border-radius: 50%;
        background-color: rgba(216, 216, 216, 0.5); }
        .chat .conversation .input-container .fav-emojis > .emoji-mart-emoji span {
          transition: 0.2s ease-out; }
          .chat .conversation .input-container .fav-emojis > .emoji-mart-emoji span:hover {
            transform: scale(1.6) translateY(-20%); }
    .chat .conversation .input-container .input-buttons-container {
      border-top: 1px solid rgba(0, 98, 211, 0.3);
      height: 100%;
      display: flex; }
      .chat .conversation .input-container .input-buttons-container textarea {
        border: none;
        border-right: 1px solid rgba(0, 98, 211, 0.3);
        resize: none;
        width: 90%;
        height: 100%; }
      .chat .conversation .input-container .input-buttons-container .form-buttons {
        min-width: 150px;
        display: flex; }
        .chat .conversation .input-container .input-buttons-container .form-buttons button.send {
          height: 50%;
          min-height: 35px;
          margin: auto; }
        .chat .conversation .input-container .input-buttons-container .form-buttons .emojis {
          margin: auto; }
          .chat .conversation .input-container .input-buttons-container .form-buttons .emojis .overlay {
            position: fixed;
            visibility: hidden; }
            .chat .conversation .input-container .input-buttons-container .form-buttons .emojis .overlay .emoji-mart-emoji > span {
              cursor: pointer; }
          .chat .conversation .input-container .input-buttons-container .form-buttons .emojis .emoji-mart-preview {
            height: 50px; }
          .chat .conversation .input-container .input-buttons-container .form-buttons .emojis div.button {
            cursor: pointer; }
  .chat .conversation .messages {
    width: 100%;
    padding: 10px 0;
    height: calc(100% - 80px);
    overflow: auto; }
    .chat .conversation .messages .message {
      width: 98%;
      margin: 5px auto; }
      .chat .conversation .messages .message p {
        font-size: 17px;
        margin: 0;
        white-space: pre-wrap;
        display: inline-block;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: whitesmoke;
        text-align: left;
        max-width: 50%;
        word-break: break-all; }
      .chat .conversation .messages .message.me {
        text-align: right; }
        .chat .conversation .messages .message.me p {
          background-color: #4387e4;
          color: white; }
    .chat .conversation .messages .time {
      position: fixed;
      visibility: hidden;
      background-color: #d0e9ff;
      padding: 4px;
      border-radius: 5px; }

@-webkit-keyframes zoom-in {
  from {
    transform: scale(0.2); }
  to {
    transform: scale(1); } }

@keyframes zoom-in {
  from {
    transform: scale(0.2); }
  to {
    transform: scale(1); } }

.dots .dot {
  width: 6px;
  height: 6px;
  background-color: #333;
  margin: 1.5px;
  border-radius: 100%;
  display: inline-block; }

.custom-dropdown {
  float: right;
  position: relative; }

.dropdown-content {
  display: none;
  position: fixed;
  overflow: auto;
  background-color: #f8fdff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1; }
  .dropdown-content span {
    color: black;
    padding: 8px;
    text-decoration: none;
    display: block; }
  .dropdown-content span:hover {
    background-color: #e6eeff; }

.show {
  display: block; }

div[content="studies"] #timeline .timeline-item:after, div[content="studies"] #timeline .timeline-item:before {
  content: "";
  display: block;
  width: 100%;
  clear: both; }

div[content="studies"] {
  min-width: 100%;
  padding: 0 10%;
  overflow-x: hidden;
  overflow: auto;
  line-height: 20px;
  margin: 0 auto;
  font-size: 14px;
  color: #726f77;
  font-family: helvetica, arial, tahoma, verdana; }
  div[content="studies"] h1,
  div[content="studies"] h2,
  div[content="studies"] h3,
  div[content="studies"] h4 {
    font-family: "Dosis", arial, tahoma, verdana; }
  div[content="studies"] .project-name {
    text-align: center;
    padding: 10px 0; }
  div[content="studies"] #timeline {
    width: 100%;
    margin: 30px auto;
    position: relative;
    overflow-x: hidden;
    padding: 0 10px;
    transition: all 0.4s ease; }
    div[content="studies"] #timeline:before {
      content: "";
      width: 3px;
      height: 100%;
      background: #3889b8;
      left: 50%;
      top: 0;
      position: absolute; }
    div[content="studies"] #timeline:after {
      content: "";
      clear: both;
      display: table;
      width: 100%; }
    div[content="studies"] #timeline .timeline-item {
      opacity: 0;
      margin-bottom: 50px;
      position: relative; }
      div[content="studies"] #timeline .timeline-item .timeline-icon {
        opacity: 0;
        background: #3889b8;
        line-height: 0.7rem;
        width: 55px;
        height: 55px;
        position: absolute;
        top: 0;
        left: 50%;
        padding: 5px;
        font-size: 12px;
        overflow: hidden;
        margin-left: -26px;
        border-radius: 50%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      div[content="studies"] #timeline .timeline-item:nth-of-type(2n + 1) .timeline-content {
        float: right; }
        div[content="studies"] #timeline .timeline-item:nth-of-type(2n + 1) .timeline-content:before {
          content: "";
          right: 45%;
          left: inherit;
          border-left: 0;
          border-right: 7px solid #3889b8; }
      div[content="studies"] #timeline .timeline-item .timeline-content {
        width: 45%;
        background: #e6f0ff;
        padding: 20px 20px 5px 20px;
        -ms-box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.13);
        box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.13);
        border-radius: 5px;
        transition: all 0.3s ease; }
        div[content="studies"] #timeline .timeline-item .timeline-content h2 {
          padding: 15px;
          background: #3889b8;
          color: #fff;
          margin: -20px -20px 0 -20px;
          font-weight: 300;
          border-radius: 3px 3px 0 0; }
        div[content="studies"] #timeline .timeline-item .timeline-content p {
          margin: 1rem 0;
          font-size: 1rem; }
          div[content="studies"] #timeline .timeline-item .timeline-content p:last-of-type {
            font-size: 1.2rem;
            font-weight: bold; }
        div[content="studies"] #timeline .timeline-item .timeline-content:before {
          content: "";
          position: absolute;
          left: 45%;
          top: 20px;
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 7px solid #3889b8; }
  @media screen and (max-width: 768px) {
    div[content="studies"] #timeline {
      margin: 30px;
      padding: 0px;
      width: 90%; }
      div[content="studies"] #timeline:before {
        left: 0; }
      div[content="studies"] #timeline .timeline-item .timeline-content {
        width: 90%;
        float: right; }
        div[content="studies"] #timeline .timeline-item .timeline-content:before, div[content="studies"] #timeline .timeline-item .timeline-content.right:before {
          left: 10%;
          margin-left: -6px;
          border-left: 0;
          border-right: 7px solid #3889b8; }
      div[content="studies"] #timeline .timeline-item .timeline-icon {
        left: 0; } }

@-webkit-keyframes slidefromleft {
  0% {
    transform: translateX(-300%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slidefromleft {
  0% {
    transform: translateX(-300%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes slidefromright {
  0% {
    transform: translateX(300%);
    opacity: 0; }
  100% {
    transform: unset;
    opacity: 1; } }

@keyframes slidefromright {
  0% {
    transform: translateX(300%);
    opacity: 0; }
  100% {
    transform: unset;
    opacity: 1; } }

@-webkit-keyframes zoomin {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes zoomin {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

.cv {
  height: 100%;
  position: absolute;
  overflow: hidden; }
  .cv .row {
    height: 100%; }
    .cv .row div[layout="profile"] {
      box-shadow: 2px 0px 10px 2.5px rgba(0, 0, 0, 0.3);
      background-color: #4372d2;
      text-align: center;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow-x: hidden; }
      .cv .row div[layout="profile"] img[alt="profile"] {
        border-radius: 50%;
        width: 150px;
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.4);
        margin-bottom: 30px; }
      .cv .row div[layout="profile"] div.link {
        margin: 10px;
        position: relative; }
        .cv .row div[layout="profile"] div.link img {
          cursor: pointer;
          width: 50px;
          transition: 0.35s all ease-in; }
        .cv .row div[layout="profile"] div.link > a,
        .cv .row div[layout="profile"] div.link > span {
          color: white;
          position: absolute;
          margin: 12px 10px 0px 10px;
          opacity: 0;
          padding: 3px 8px;
          border-radius: 10px;
          border: 1px solid #1c4496;
          background-color: #20448b;
          transition: 0.5s all ease-in;
          white-space: nowrap; }
    .cv .row div[layout="contents"] {
      padding: 0;
      height: 100%; }
      .cv .row div[layout="contents"] > div:first-of-type {
        text-align: center;
        width: 100%;
        height: 52px;
        box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.25); }
        .cv .row div[layout="contents"] > div:first-of-type .progress {
          height: 0.3rem;
          border-radius: 0;
          background-color: #e6f0ff; }
          .cv .row div[layout="contents"] > div:first-of-type .progress div {
            background-color: #81abff; }
        .cv .row div[layout="contents"] > div:first-of-type span {
          width: 25%;
          display: inline-block;
          font-size: 25px;
          padding: 5px;
          background-color: #e6f0ff;
          border-right: 1px solid #bed7ff; }
      .cv .row div[layout="contents"] > div:not(:first-of-type) {
        overflow: auto;
        height: calc(100% - 52px); }
        .cv .row div[layout="contents"] > div:not(:first-of-type) > div:not(:first-of-type) {
          height: 500px; }


.portfolio {
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(0deg, #020024 0%, #08618b 100%);
  background-position-y: 70vh; }
  .portfolio #custom-navbar .logo {
    font-weight: bold;
    font-size: 2.5em;
    letter-spacing: 0px;
    color: #529ac0;
    margin: 0 2rem; }
    .portfolio #custom-navbar .logo a {
      text-decoration: none; }
  .portfolio #custom-navbar.bg-dark {
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.35); }
  .portfolio #custom-navbar.fadein-navbar {
    flex-flow: nowrap;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    height: 60px;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 0.8em;
    transition: all 0.4s ease-in-out; }
  .portfolio #custom-navbar .navbar-collapse {
    justify-content: flex-end;
    margin-right: 6%; }
    .portfolio #custom-navbar .navbar-collapse.show, .portfolio #custom-navbar .navbar-collapse.collapsing {
      position: absolute;
      margin-right: 0;
      right: 0;
      top: 100%;
      background-color: #343a40;
      padding: 2vh 3.5vw 0.5vh 2vw; }
      .portfolio #custom-navbar .navbar-collapse.show .navbar-nav, .portfolio #custom-navbar .navbar-collapse.collapsing .navbar-nav {
        width: auto;
        text-align: right;
        margin-bottom: 2vh;
        display: block; }
        .portfolio #custom-navbar .navbar-collapse.show .navbar-nav a, .portfolio #custom-navbar .navbar-collapse.collapsing .navbar-nav a {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; }
    .portfolio #custom-navbar .navbar-collapse a {
      margin: 0 1vw;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      color: whitesmoke;
      transition: 0.25s; }
      .portfolio #custom-navbar .navbar-collapse a:hover {
        text-decoration: none;
        transform: scale(1.25) translateY(0.25px); }
  .portfolio #custom-navbar .toggler-custom {
    border: none; }
  .portfolio #custom-navbar .navbar-toggler {
    border: none;
    outline: none; }

.portfolio #Media {
  padding: 0; }
  .portfolio #Media img {
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    position: relative;
    overflow: hidden;
    object-fit: cover; }
  .portfolio #Media .legend {
    position: absolute;
    bottom: 3px;
    right: 10px;
    font-weight: 100;
    color: rgba(245, 245, 245, 0.74); }
  .portfolio #Media .write-auto {
    position: absolute;
    top: 30vmin;
    left: 8%;
    max-width: 40vw;
    color: white;
    font-weight: 1000;
    font-size: 3.5em; }
    .portfolio #Media .write-auto::after {
      color: black; }

.portfolio .about {
  min-height: 100vh;
  padding: 0 2.75vw 0 2.5vw; }
  .portfolio .about .card-header {
    height: unset;
    font-weight: 400;
    font-size: 2rem;
    padding: 2.5vh 2.5vh 2vh;
    margin-bottom: 0;
    text-align: left; }
  .portfolio .about p {
    text-align: justify;
    white-space: pre-line;
    margin-bottom: 0; }
  .portfolio .about .row {
    max-width: 100vw; }
  .portfolio .about img.media-about {
    box-shadow: 0px 2.5px 4px 3px rgba(150, 150, 150, 0.35);
    float: left;
    margin-left: 1rem;
    width: 38vw;
    min-height: 40vh;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    object-position: right;
    height: calc(80vh - 60px); }
  .portfolio .about .col#social-desc {
    display: grid;
    align-content: space-between; }
  .portfolio .about #social .row {
    justify-content: space-evenly; }
    .portfolio .about #social .row:not(:last-of-type) {
      margin-bottom: 1rem; }
  .portfolio .about .btn {
    word-break: break-all; }
    @media screen and (max-width: 852px) {
      .portfolio .about .btn:not(:last-of-type) {
        margin-bottom: 1rem; } }
    .portfolio .about .btn.phone svg {
      transform: rotate(90deg); }
    .portfolio .about .btn svg {
      margin-right: 8px; }
    .portfolio .about .btn #desc p {
      text-align: justify;
      margin-bottom: 0; }
  .portfolio .about .small .row:first-of-type {
    margin-bottom: 2vh; }
  .portfolio .about .small .phone {
    margin-bottom: 1rem; }
  .portfolio .about .small img.media-about {
    margin-left: unset;
    height: calc(40vh - 60px); }

.portfolio #Projects {
  min-height: 100vh; }
  .portfolio #Projects a {
    text-decoration: none;
    color: inherit; }
  .portfolio #Projects .card {
    border: none;
    transition: 0.25s;
    cursor: pointer;
    margin-bottom: 1rem; }
    .portfolio #Projects .card:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(200, 200, 200, 0.5); }
    .portfolio #Projects .card .card-header {
      font-size: 1rem;
      padding: 0.75rem 0.75rem; }
      .portfolio #Projects .card .card-header .card-title {
        text-shadow: 0.5px 1px 1px #343a40;
        color: #062f50; }
      .portfolio #Projects .card .card-header .images {
        display: flex;
        justify-content: space-around; }
        .portfolio #Projects .card .card-header .images img {
          width: 28%; }
      .portfolio #Projects .card .card-header img {
        margin-bottom: 0.75rem; }
    .portfolio #Projects .card .card-body {
      justify-content: space-around;
      display: flex;
      padding: 1rem; }
      .portfolio #Projects .card .card-body svg {
        margin-bottom: 0.3rem;
        margin-right: 5px;
        font-size: 1.2rem; }
    .portfolio #Projects .card .card-footer {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-evenly;
      font-size: 1.8rem; }
      .portfolio #Projects .card .card-footer svg.php {
        transform: scale(1.5); }
      .portfolio #Projects .card .card-footer img {
        width: 1.8rem; }
        .portfolio #Projects .card .card-footer img.firebase {
          -webkit-filter: grayscale(1) brightness(0.5);
                  filter: grayscale(1) brightness(0.5);
          height: 2.1rem;
          width: 2.1rem; }

.portfolio #Experience {
  min-height: 100vh; }
  .portfolio #Experience .vertical-timeline-element p {
    text-align: justify;
    white-space: pre-line; }
  .portfolio #Experience .vertical-timeline-element h3 {
    font-weight: bold; }
  .portfolio #Experience .vertical-timeline-element h5 {
    font-style: italic; }
  .portfolio #Experience .vertical-timeline-element .vertical-timeline-element-date {
    color: white; }
  .portfolio #Experience .vertical-timeline-element .vertical-timeline-element-icon {
    color: white; }
  .portfolio #Experience .vertical-timeline-element.work .vertical-timeline-element-icon {
    background: #1e96f0; }
  .portfolio #Experience .vertical-timeline-element.work.current .vertical-timeline-element-content {
    background: #2196f0;
    color: #fff;
    box-shadow: 0 3px 0 #88b7dc; }
  .portfolio #Experience .vertical-timeline-element.work.current .vertical-timeline-element-content-arrow {
    border-right: 7px solid #2196f0; }
  .portfolio #Experience .vertical-timeline-element.school .vertical-timeline-element-icon {
    background: #2ab875; }
  .portfolio #Experience .vertical-timeline-element.school.current .vertical-timeline-element-content {
    background: #2ab875;
    color: #fff;
    box-shadow: 0 3px 0 #88dc96; }
  .portfolio #Experience .vertical-timeline-element.school.current .vertical-timeline-element-content-arrow {
    border-right: 7px solid #2ab875; }

.portfolio h1 {
  color: whitesmoke; }

.portfolio h1 {
  font-weight: bold;
  padding: 2.5vh 2.5vh;
  margin-bottom: 0;
  text-align: left; }

.portfolio .card {
  box-shadow: 0 5px 12px rgba(250, 250, 250, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2rem; }
  .portfolio .card .card-title {
    font-size: 1.6rem;
    font-weight: bold; }
  .portfolio .card .card-text {
    justify-content: space-evenly; }

.platform {
  margin: auto !important;
  width: 90vw !important;
  height: 80vh !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; }
  .platform #\#canvas {
    width: 100%;
    height: 100%; }

button.nightshift{position:absolute;top:0;margin-top:-60px;left:7px;width:47px;height:47px;z-index:1;vertical-align:middle;display:inline-block;overflow:hidden;border-radius:50%;padding:0;cursor:pointer}button.nightshift:not(.btn-indigo){opacity:0.7}button.nightshift .fa{font-size:1.4rem;color:#d9a84d}div.nightshift{top:0;left:0;width:100vw;height:100vh;position:fixed;pointer-events:none;opacity:0.12;background-color:#c39616;z-index:99999}

footer{z-index:1;position:-webkit-sticky;position:sticky;bottom:0;left:0;width:100%;background-color:#1077d0;color:white;padding:0.8rem}footer a{color:white;cursor:pointer;font-size:1rem;padding-bottom:1px;text-decoration:none !important}footer a:hover{transition:none;color:white;border-bottom:1px solid #bfdaff}@media screen{footer{visibility:hidden;padding:0}footer div:not(.nightshift){display:none}.nightshift{visibility:visible}}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 4;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  height: 60px;
  top: 0;
  right: 0;
  background-color: #2460da;
  vertical-align: top;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.3); }
  header .auth {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto 5px;
    padding: 2px;
    font-size: 20px;
    color: #eef6ff; }
    header .auth button {
      margin-left: 10px;
      font-size: 17px;
      padding: 0.64rem 1.54rem; }
    header .auth div.buttons {
      height: 100%; }
  header button.home {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto 5px;
    padding: 2px; }
    header button.home svg {
      left: 0;
      top: 0;
      padding: 5px;
      margin: 5.5px;
      font-size: 35px; }
  header .user {
    display: flex;
    float: right; }
    header .user div.dropdown {
      margin: auto 5px;
      padding: 2px;
      width: 100%; }
      header .user div.dropdown button {
        display: flex;
        padding: 0.2rem 0.75rem; }
        header .user div.dropdown button div.user-buttons {
          display: inline-flex; }
          header .user div.dropdown button div.user-buttons img {
            float: right;
            padding: 2px;
            width: 40px;
            border-radius: 20px;
            cursor: pointer;
            background-color: white; }
          header .user div.dropdown button div.user-buttons span {
            margin-right: 4%;
            text-transform: none;
            color: white;
            font-family: "Roboto";
            font-weight: 400;
            letter-spacing: 1.2px;
            font-size: 25px; }
        header .user div.dropdown button::after {
          display: none;
          top: 18px;
          position: relative; }
      header .user div.dropdown .dropdown-menu {
        width: 100%;
        min-width: unset;
        margin-left: 5%;
        border-radius: 0 0 0.25rem 0.25rem;
        padding: 0;
        margin: unset; }
        header .user div.dropdown .dropdown-menu .dropdown-item {
          text-align: center;
          display: inline-block; }
          header .user div.dropdown .dropdown-menu .dropdown-item:hover {
            text-decoration: underline;
            cursor: pointer; }
    header .user .logout {
      font-family: "Roboto";
      font-weight: 300;
      font-size: 25px;
      letter-spacing: 1px;
      margin: auto 6px;
      padding: 2px 8px;
      border-radius: 5px;
      color: white; }
      header .user .logout .fa {
        font-size: 1.5rem;
        margin-left: 6px; }

.modal-container {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  transition: 0.4s all;
  background-color: rgba(0, 0, 0, 0.7); }
  .modal-container .dialog {
    z-index: 3;
    padding: 20px 35px;
    background-color: #f5faff;
    position: absolute;
    left: 50%;
    top: 43%;
    transform: translate(-50%, -50%);
    margin: unset;
    border-radius: 4px;
    box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.55);
    pointer-events: all;
    -webkit-animation: zoom-in 0.6s;
            animation: zoom-in 0.6s;
    transition: 0.4s all; }
  .modal-container[display="off"] .dialog {
    transform: translate(-50%, -50%) scale(0.1); }
  .modal-container[display="on"] {
    -webkit-animation: fade-in 0.5s;
            animation: fade-in 0.5s;
    opacity: 1;
    visibility: visible; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@-webkit-keyframes zoom-in {
  0% {
    transform: translate(-50%, -50%) scale(0.1); }
  100% {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes zoom-in {
  0% {
    transform: translate(-50%, -50%) scale(0.1); }
  100% {
    transform: translate(-50%, -50%) scale(1); } }

@-webkit-keyframes zoom-out {
  0% {
    transform: translate(-50%, -50%) scale(1); }
  100% {
    transform: translate(-50%, -50%) scale(0.1); } }

@keyframes zoom-out {
  0% {
    transform: translate(-50%, -50%) scale(1); }
  100% {
    transform: translate(-50%, -50%) scale(0.1); } }

.connect {
  margin: auto;
  text-align: center;
  min-width: 300px;
  width: 25vw;
  max-width: 350px; }
  .connect button {
    letter-spacing: 2px;
    font-size: 1rem;
    margin: auto;
    display: block; }
  .connect a {
    display: inline-block;
    margin-top: 5px; }
  .connect .input-group.password .input-group-append > span button {
    box-shadow: unset !important;
    background-color: unset;
    border: unset;
    outline: unset !important;
    padding: 0; }

@-webkit-keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-30px); }
  20%,
  80% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-30px); } }

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-30px); }
  20%,
  80% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-30px); } }

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.btn:disabled {
    cursor: not-allowed;
}

#root > div:first-of-type {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#root > div:first-of-type > div:nth-of-type(2) {
    flex: 1 1;
    position: relative;
}

