footer {
    z-index: 1;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #1077d0;
    color: white;
    padding: 0.8rem;
    a {
        color: white;
        cursor: pointer;
        font-size: 1rem;
        padding-bottom: 1px;
        text-decoration: none !important;
        &:hover {
            transition: none;
            color: white;
            border-bottom: 1px solid #bfdaff;
        }
    }
}


@media screen /*and (max-width: 835px),
(max-height: 430px)*/ {
    footer {
        visibility: hidden;
        padding: 0;
        div:not(.nightshift) {
            display: none;
        }
    }
    .nightshift {
        visibility: visible;
    }
}