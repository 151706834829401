.todolist button.cancel {
  margin-left: 10px; }

.todolist .list-group-item {
  padding: 0.25rem 0.65rem;
  display: table;
  justify-content: space-between;
  width: 100%; }
  .todolist .list-group-item .text {
    display: table-cell;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    vertical-align: middle; }
  .todolist .list-group-item .buttons {
    height: 48.25px;
    width: 184px;
    display: table-cell;
    vertical-align: middle; }
    .todolist .list-group-item .buttons button {
      width: 48px;
      padding: 0.34rem 0.94rem; }
  .todolist .list-group-item:not(.list-group-item-action) .text {
    color: gray; }
  .todolist .list-group-item:not(.list-group-item-action) .buttons {
    opacity: 0.4; }

.todolist .list-group {
  display: inline-block;
  text-align: center; }
  .todolist .list-group > div {
    text-align: left;
    width: 49%;
    margin: 2px 0.5%;
    display: inline-flex; }
