button.nightshift {
    position: absolute;
    top: 0;
    margin-top: -60px;
    left: 7px;
    width: 47px;
    height: 47px;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    &:not(.btn-indigo) {
        opacity: 0.7;
    }
    .fa {
        font-size: 1.4rem;
        color: #d9a84d;
    }
}

div.nightshift {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    pointer-events: none;
    opacity: 0.12;
    background-color: #c39616;
    z-index: 99999;
}