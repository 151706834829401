.portfolio .about {
  min-height: 100vh;
  padding: 0 2.75vw 0 2.5vw; }
  .portfolio .about .card-header {
    height: unset;
    font-weight: 400;
    font-size: 2rem;
    padding: 2.5vh 2.5vh 2vh;
    margin-bottom: 0;
    text-align: left; }
  .portfolio .about p {
    text-align: justify;
    white-space: pre-line;
    margin-bottom: 0; }
  .portfolio .about .row {
    max-width: 100vw; }
  .portfolio .about img.media-about {
    box-shadow: 0px 2.5px 4px 3px rgba(150, 150, 150, 0.35);
    float: left;
    margin-left: 1rem;
    width: 38vw;
    min-height: 40vh;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    object-position: right;
    height: calc(80vh - 60px); }
  .portfolio .about .col#social-desc {
    display: grid;
    align-content: space-between; }
  .portfolio .about #social .row {
    justify-content: space-evenly; }
    .portfolio .about #social .row:not(:last-of-type) {
      margin-bottom: 1rem; }
  .portfolio .about .btn {
    word-break: break-all; }
    @media screen and (max-width: 852px) {
      .portfolio .about .btn:not(:last-of-type) {
        margin-bottom: 1rem; } }
    .portfolio .about .btn.phone svg {
      transform: rotate(90deg); }
    .portfolio .about .btn svg {
      margin-right: 8px; }
    .portfolio .about .btn #desc p {
      text-align: justify;
      margin-bottom: 0; }
  .portfolio .about .small .row:first-of-type {
    margin-bottom: 2vh; }
  .portfolio .about .small .phone {
    margin-bottom: 1rem; }
  .portfolio .about .small img.media-about {
    margin-left: unset;
    height: calc(40vh - 60px); }
