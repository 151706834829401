.portfolio #Projects {
  min-height: 100vh; }
  .portfolio #Projects a {
    text-decoration: none;
    color: inherit; }
  .portfolio #Projects .card {
    border: none;
    transition: 0.25s;
    cursor: pointer;
    margin-bottom: 1rem; }
    .portfolio #Projects .card:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(200, 200, 200, 0.5); }
    .portfolio #Projects .card .card-header {
      font-size: 1rem;
      padding: 0.75rem 0.75rem; }
      .portfolio #Projects .card .card-header .card-title {
        text-shadow: 0.5px 1px 1px #343a40;
        color: #062f50; }
      .portfolio #Projects .card .card-header .images {
        display: flex;
        justify-content: space-around; }
        .portfolio #Projects .card .card-header .images img {
          width: 28%; }
      .portfolio #Projects .card .card-header img {
        margin-bottom: 0.75rem; }
    .portfolio #Projects .card .card-body {
      justify-content: space-around;
      display: flex;
      padding: 1rem; }
      .portfolio #Projects .card .card-body svg {
        margin-bottom: 0.3rem;
        margin-right: 5px;
        font-size: 1.2rem; }
    .portfolio #Projects .card .card-footer {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-evenly;
      font-size: 1.8rem; }
      .portfolio #Projects .card .card-footer svg.php {
        transform: scale(1.5); }
      .portfolio #Projects .card .card-footer img {
        width: 1.8rem; }
        .portfolio #Projects .card .card-footer img.firebase {
          filter: grayscale(1) brightness(0.5);
          height: 2.1rem;
          width: 2.1rem; }
