@import "https://fonts.googleapis.com/css?family=Lato:400,900i";
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 4;
  position: sticky;
  width: 100%;
  height: 60px;
  top: 0;
  right: 0;
  background-color: #2460da;
  vertical-align: top;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.3); }
  header .auth {
    height: fit-content;
    margin: auto 5px;
    padding: 2px;
    font-size: 20px;
    color: #eef6ff; }
    header .auth button {
      margin-left: 10px;
      font-size: 17px;
      padding: 0.64rem 1.54rem; }
    header .auth div.buttons {
      height: 100%; }
  header button.home {
    height: fit-content;
    margin: auto 5px;
    padding: 2px; }
    header button.home svg {
      left: 0;
      top: 0;
      padding: 5px;
      margin: 5.5px;
      font-size: 35px; }
  header .user {
    display: flex;
    float: right; }
    header .user div.dropdown {
      margin: auto 5px;
      padding: 2px;
      width: 100%; }
      header .user div.dropdown button {
        display: flex;
        padding: 0.2rem 0.75rem; }
        header .user div.dropdown button div.user-buttons {
          display: inline-flex; }
          header .user div.dropdown button div.user-buttons img {
            float: right;
            padding: 2px;
            width: 40px;
            border-radius: 20px;
            cursor: pointer;
            background-color: white; }
          header .user div.dropdown button div.user-buttons span {
            margin-right: 4%;
            text-transform: none;
            color: white;
            font-family: "Roboto";
            font-weight: 400;
            letter-spacing: 1.2px;
            font-size: 25px; }
        header .user div.dropdown button::after {
          display: none;
          top: 18px;
          position: relative; }
      header .user div.dropdown .dropdown-menu {
        width: 100%;
        min-width: unset;
        margin-left: 5%;
        border-radius: 0 0 0.25rem 0.25rem;
        padding: 0;
        margin: unset; }
        header .user div.dropdown .dropdown-menu .dropdown-item {
          text-align: center;
          display: inline-block; }
          header .user div.dropdown .dropdown-menu .dropdown-item:hover {
            text-decoration: underline;
            cursor: pointer; }
    header .user .logout {
      font-family: "Roboto";
      font-weight: 300;
      font-size: 25px;
      letter-spacing: 1px;
      margin: auto 6px;
      padding: 2px 8px;
      border-radius: 5px;
      color: white; }
      header .user .logout .fa {
        font-size: 1.5rem;
        margin-left: 6px; }
