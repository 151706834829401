.dots .dot {
  width: 6px;
  height: 6px;
  background-color: #333;
  margin: 1.5px;
  border-radius: 100%;
  display: inline-block; }

.custom-dropdown {
  float: right;
  position: relative; }

.dropdown-content {
  display: none;
  position: fixed;
  overflow: auto;
  background-color: #f8fdff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1; }
  .dropdown-content span {
    color: black;
    padding: 8px;
    text-decoration: none;
    display: block; }
  .dropdown-content span:hover {
    background-color: #e6eeff; }

.show {
  display: block; }
