body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.btn:disabled {
    cursor: not-allowed;
}

#root > div:first-of-type {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#root > div:first-of-type > div:nth-of-type(2) {
    flex: 1;
    position: relative;
}
