.cv {
  height: 100%;
  position: absolute;
  overflow: hidden; }
  .cv .row {
    height: 100%; }
    .cv .row div[layout="profile"] {
      box-shadow: 2px 0px 10px 2.5px rgba(0, 0, 0, 0.3);
      background-color: #4372d2;
      text-align: center;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow-x: hidden; }
      .cv .row div[layout="profile"] img[alt="profile"] {
        border-radius: 50%;
        width: 150px;
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.4);
        margin-bottom: 30px; }
      .cv .row div[layout="profile"] div.link {
        margin: 10px;
        position: relative; }
        .cv .row div[layout="profile"] div.link img {
          cursor: pointer;
          width: 50px;
          transition: 0.35s all ease-in; }
        .cv .row div[layout="profile"] div.link > a,
        .cv .row div[layout="profile"] div.link > span {
          color: white;
          position: absolute;
          margin: 12px 10px 0px 10px;
          opacity: 0;
          padding: 3px 8px;
          border-radius: 10px;
          border: 1px solid #1c4496;
          background-color: #20448b;
          transition: 0.5s all ease-in;
          white-space: nowrap; }
    .cv .row div[layout="contents"] {
      padding: 0;
      height: 100%; }
      .cv .row div[layout="contents"] > div:first-of-type {
        text-align: center;
        width: 100%;
        height: 52px;
        box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.25); }
        .cv .row div[layout="contents"] > div:first-of-type .progress {
          height: 0.3rem;
          border-radius: 0;
          background-color: #e6f0ff; }
          .cv .row div[layout="contents"] > div:first-of-type .progress div {
            background-color: #81abff; }
        .cv .row div[layout="contents"] > div:first-of-type span {
          width: 25%;
          display: inline-block;
          font-size: 25px;
          padding: 5px;
          background-color: #e6f0ff;
          border-right: 1px solid #bed7ff; }
      .cv .row div[layout="contents"] > div:not(:first-of-type) {
        overflow: auto;
        height: calc(100% - 52px); }
        .cv .row div[layout="contents"] > div:not(:first-of-type) > div:not(:first-of-type) {
          height: 500px; }
