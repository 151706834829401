.modal-container {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  transition: 0.4s all;
  background-color: rgba(0, 0, 0, 0.7); }
  .modal-container .dialog {
    z-index: 3;
    padding: 20px 35px;
    background-color: #f5faff;
    position: absolute;
    left: 50%;
    top: 43%;
    transform: translate(-50%, -50%);
    margin: unset;
    border-radius: 4px;
    box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.55);
    pointer-events: all;
    animation: zoom-in 0.6s;
    transition: 0.4s all; }
  .modal-container[display="off"] .dialog {
    transform: translate(-50%, -50%) scale(0.1); }
  .modal-container[display="on"] {
    animation: fade-in 0.5s;
    opacity: 1;
    visibility: visible; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes zoom-in {
  0% {
    transform: translate(-50%, -50%) scale(0.1); }
  100% {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes zoom-out {
  0% {
    transform: translate(-50%, -50%) scale(1); }
  100% {
    transform: translate(-50%, -50%) scale(0.1); } }
