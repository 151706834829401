.live {
  margin-top: 3vh; }
  .live h1 {
    display: inline; }
  .live h2 {
    font-family: 'Roboto';
    font-weight: 100;
    display: inline-block; }
  .live input {
    min-width: 100px;
    width: 20vw;
    display: inline-block; }
  .live span {
    display: inline-block;
    font-size: 2rem;
    background-color: #b3e0e7;
    border-radius: 5px;
    padding: 0.6rem; }

@media screen and (max-width: 550px) {
  .live {
    margin-top: 30vh; } }

.live-game {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; }
