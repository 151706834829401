.col {
  text-align: center; }

.card-title {
  font-size: 2rem; }

.card-text {
  text-align: justify; }

.sort-container {
  left: 70px;
  z-index: 1;
  color: white;
  background: #2658a9;
  position: absolute;
  margin-top: 10px;
  padding-top: 5px;
  width: 200px;
  height: 80px;
  border-radius: 5px;
  box-shadow: 0px 1.5px 8px 0.5px rgba(0, 0, 0, 0.2); }
  .sort-container:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #2658a9;
    border-left: 0;
    margin-top: -40px;
    margin-left: -10px; }
  .sort-container button {
    cursor: pointer; }

button.filter {
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: 50%;
  position: absolute;
  padding: 10px 15px;
  margin: 10px; }
  button.filter .fa-filter:before {
    font-size: 1.5rem; }

.apps-wrapper {
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding: 0 4vw; }
  .apps-wrapper > div {
    margin-top: 10px; }
  .apps-wrapper .card {
    left: 0px;
    top: 0px;
    border: unset;
    box-shadow: 0px 1px 3px 0.5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    box-sizing: border-box;
    display: inline-flex;
    margin: 10px;
    padding: 0 0 3px 0;
    width: 250px;
    height: 200px;
    z-index: 0;
    overflow: overlay;
    transition: 0.25s ease; }
    .apps-wrapper .card:hover {
      transform: translateY(-10px);
      box-shadow: 0px 1.5px 8px 0.5px rgba(0, 0, 0, 0.25); }
    .apps-wrapper .card[private="true"] {
      filter: opacity(50%); }
    .apps-wrapper .card[disabled] {
      filter: opacity(50%);
      pointer-events: none; }
    .apps-wrapper .card .fa {
      margin-left: 3px; }
    .apps-wrapper .card .btn {
      margin-bottom: 2px; }
    .apps-wrapper .card .card-title {
      margin-bottom: 0.55rem;
      padding-bottom: 0.35rem;
      border-bottom: 1px solid #4285f4; }
    .apps-wrapper .card .card-body {
      padding-top: 10px; }
    .apps-wrapper .card .card-header {
      border-radius: 15px 15px 0 0;
      background-color: rgba(44, 104, 218, 0.5);
      cursor: grab; }
    .apps-wrapper .card .card-bottom > span {
      font-style: italic;
      font-size: 0.78rem;
      display: block;
      margin-top: 2px;
      color: #747373; }
