.connect {
  margin: auto;
  text-align: center;
  min-width: 300px;
  width: 25vw;
  max-width: 350px; }
  .connect button {
    letter-spacing: 2px;
    font-size: 1rem;
    margin: auto;
    display: block; }
  .connect a {
    display: inline-block;
    margin-top: 5px; }
  .connect .input-group.password .input-group-append > span button {
    box-shadow: unset !important;
    background-color: unset;
    border: unset;
    outline: unset !important;
    padding: 0; }

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-30px); }
  20%,
  80% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-30px); } }
