.portfolio h1 {
  color: whitesmoke; }

.portfolio h1 {
  font-weight: bold;
  padding: 2.5vh 2.5vh;
  margin-bottom: 0;
  text-align: left; }

.portfolio .card {
  box-shadow: 0 5px 12px rgba(250, 250, 250, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2rem; }
  .portfolio .card .card-title {
    font-size: 1.6rem;
    font-weight: bold; }
  .portfolio .card .card-text {
    justify-content: space-evenly; }
