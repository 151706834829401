.portfolio {
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(0deg, #020024 0%, #08618b 100%);
  background-position-y: 70vh; }
  .portfolio #custom-navbar .logo {
    font-weight: bold;
    font-size: 2.5em;
    letter-spacing: 0px;
    color: #529ac0;
    margin: 0 2rem; }
    .portfolio #custom-navbar .logo a {
      text-decoration: none; }
  .portfolio #custom-navbar.bg-dark {
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.35); }
  .portfolio #custom-navbar.fadein-navbar {
    flex-flow: nowrap;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    height: 60px;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 0.8em;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .portfolio #custom-navbar .navbar-collapse {
    justify-content: flex-end;
    margin-right: 6%; }
    .portfolio #custom-navbar .navbar-collapse.show, .portfolio #custom-navbar .navbar-collapse.collapsing {
      position: absolute;
      margin-right: 0;
      right: 0;
      top: 100%;
      background-color: #343a40;
      padding: 2vh 3.5vw 0.5vh 2vw; }
      .portfolio #custom-navbar .navbar-collapse.show .navbar-nav, .portfolio #custom-navbar .navbar-collapse.collapsing .navbar-nav {
        width: auto;
        text-align: right;
        margin-bottom: 2vh;
        display: block; }
        .portfolio #custom-navbar .navbar-collapse.show .navbar-nav a, .portfolio #custom-navbar .navbar-collapse.collapsing .navbar-nav a {
          width: fit-content; }
    .portfolio #custom-navbar .navbar-collapse a {
      margin: 0 1vw;
      width: max-content;
      color: whitesmoke;
      transition: 0.25s; }
      .portfolio #custom-navbar .navbar-collapse a:hover {
        text-decoration: none;
        transform: scale(1.25) translateY(0.25px); }
  .portfolio #custom-navbar .toggler-custom {
    border: none; }
  .portfolio #custom-navbar .navbar-toggler {
    border: none;
    outline: none; }
