@import "https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700";
div[content="studies"] #timeline .timeline-item:after, div[content="studies"] #timeline .timeline-item:before {
  content: "";
  display: block;
  width: 100%;
  clear: both; }

div[content="studies"] {
  min-width: 100%;
  padding: 0 10%;
  overflow-x: hidden;
  overflow: auto;
  line-height: 20px;
  margin: 0 auto;
  font-size: 14px;
  color: #726f77;
  font-family: helvetica, arial, tahoma, verdana; }
  div[content="studies"] h1,
  div[content="studies"] h2,
  div[content="studies"] h3,
  div[content="studies"] h4 {
    font-family: "Dosis", arial, tahoma, verdana; }
  div[content="studies"] .project-name {
    text-align: center;
    padding: 10px 0; }
  div[content="studies"] #timeline {
    width: 100%;
    margin: 30px auto;
    position: relative;
    overflow-x: hidden;
    padding: 0 10px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    div[content="studies"] #timeline:before {
      content: "";
      width: 3px;
      height: 100%;
      background: #3889b8;
      left: 50%;
      top: 0;
      position: absolute; }
    div[content="studies"] #timeline:after {
      content: "";
      clear: both;
      display: table;
      width: 100%; }
    div[content="studies"] #timeline .timeline-item {
      opacity: 0;
      margin-bottom: 50px;
      position: relative; }
      div[content="studies"] #timeline .timeline-item .timeline-icon {
        opacity: 0;
        background: #3889b8;
        line-height: 0.7rem;
        width: 55px;
        height: 55px;
        position: absolute;
        top: 0;
        left: 50%;
        padding: 5px;
        font-size: 12px;
        overflow: hidden;
        margin-left: -26px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      div[content="studies"] #timeline .timeline-item:nth-of-type(2n + 1) .timeline-content {
        float: right; }
        div[content="studies"] #timeline .timeline-item:nth-of-type(2n + 1) .timeline-content:before {
          content: "";
          right: 45%;
          left: inherit;
          border-left: 0;
          border-right: 7px solid #3889b8; }
      div[content="studies"] #timeline .timeline-item .timeline-content {
        width: 45%;
        background: #e6f0ff;
        padding: 20px 20px 5px 20px;
        -webkit-box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.13);
        -moz-box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.13);
        -ms-box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.13);
        box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.13);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        div[content="studies"] #timeline .timeline-item .timeline-content h2 {
          padding: 15px;
          background: #3889b8;
          color: #fff;
          margin: -20px -20px 0 -20px;
          font-weight: 300;
          -webkit-border-radius: 3px 3px 0 0;
          -moz-border-radius: 3px 3px 0 0;
          -ms-border-radius: 3px 3px 0 0;
          border-radius: 3px 3px 0 0; }
        div[content="studies"] #timeline .timeline-item .timeline-content p {
          margin: 1rem 0;
          font-size: 1rem; }
          div[content="studies"] #timeline .timeline-item .timeline-content p:last-of-type {
            font-size: 1.2rem;
            font-weight: bold; }
        div[content="studies"] #timeline .timeline-item .timeline-content:before {
          content: "";
          position: absolute;
          left: 45%;
          top: 20px;
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 7px solid #3889b8; }
  @media screen and (max-width: 768px) {
    div[content="studies"] #timeline {
      margin: 30px;
      padding: 0px;
      width: 90%; }
      div[content="studies"] #timeline:before {
        left: 0; }
      div[content="studies"] #timeline .timeline-item .timeline-content {
        width: 90%;
        float: right; }
        div[content="studies"] #timeline .timeline-item .timeline-content:before, div[content="studies"] #timeline .timeline-item .timeline-content.right:before {
          left: 10%;
          margin-left: -6px;
          border-left: 0;
          border-right: 7px solid #3889b8; }
      div[content="studies"] #timeline .timeline-item .timeline-icon {
        left: 0; } }

@keyframes slidefromleft {
  0% {
    transform: translateX(-300%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slidefromright {
  0% {
    transform: translateX(300%);
    opacity: 0; }
  100% {
    transform: unset;
    opacity: 1; } }

@keyframes zoomin {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }
