.portfolio #Experience {
  min-height: 100vh; }
  .portfolio #Experience .vertical-timeline-element p {
    text-align: justify;
    white-space: pre-line; }
  .portfolio #Experience .vertical-timeline-element h3 {
    font-weight: bold; }
  .portfolio #Experience .vertical-timeline-element h5 {
    font-style: italic; }
  .portfolio #Experience .vertical-timeline-element .vertical-timeline-element-date {
    color: white; }
  .portfolio #Experience .vertical-timeline-element .vertical-timeline-element-icon {
    color: white; }
  .portfolio #Experience .vertical-timeline-element.work .vertical-timeline-element-icon {
    background: #1e96f0; }
  .portfolio #Experience .vertical-timeline-element.work.current .vertical-timeline-element-content {
    background: #2196f0;
    color: #fff;
    box-shadow: 0 3px 0 #88b7dc; }
  .portfolio #Experience .vertical-timeline-element.work.current .vertical-timeline-element-content-arrow {
    border-right: 7px solid #2196f0; }
  .portfolio #Experience .vertical-timeline-element.school .vertical-timeline-element-icon {
    background: #2ab875; }
  .portfolio #Experience .vertical-timeline-element.school.current .vertical-timeline-element-content {
    background: #2ab875;
    color: #fff;
    box-shadow: 0 3px 0 #88dc96; }
  .portfolio #Experience .vertical-timeline-element.school.current .vertical-timeline-element-content-arrow {
    border-right: 7px solid #2ab875; }
